.inactiveDialog {
}

.logo {
    margin-top: 8px;
    left: 25px;
    position: absolute;
    height: 50px;
}

.inactiveDialogPaper {
    opacity: 0.95;
    display: flex;
    flex-direction: column;
}

.inactiveDialogTitle {
    padding-left: 80px !important;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px !important;
    line-height: 25px;
    white-space: wrap;
}
