@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.socialBtn {
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: 0.5px;
    line-height: 1.5;
    font-size: 13px !important;
    font-weight: 600 !important;
    text-overflow: ellipsis !important;
    color: #3c4043 !important;
}

.socialBtnParent:hover {
    border: 1px solid #a3bae786 !important;
}
