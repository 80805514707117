.accordion-details {
    background: #F5FAFF;
    border-radius: 5px;
}
.bg-aqua {
    background-color: #F5FAFF;
}
.iframe{
    border: 1px solid #e8e8e8 !important;
}
.font-blue {
    color: #004489;
}
.font-weight {
    font-weight: 600 !important;
}
.mb-30 {
    margin: 0px 0px 30px 0px !important;
}
.title {
    font-style: normal;
    /* font-size: 32px !important; */
}
.btnLoginGoogle {
    border-radius: 30px !important;
    height: 100%;
    width: 100%;
    color: #004489 !important;
    padding: 16px 15px !important;
    box-shadow: none !important;
    border: 1px solid #E8E8E8 !important;
    margin: 10px 0 !important;
}
.btnLoginGoogle:hover {
    opacity: 1 !important;
}
.btnLoginGoogle span {
    text-align: center;
    margin-left: -45px;
    width: 100% !important;
    font-weight: 500;
    font-size: 16px;
}
.tokenInput {
    font-size: 30px !important;
    color: #004489 !important;
    border-radius: 8px !important;
    padding-left: 0.6rem !important;
    width: 4rem;
    height: 4rem;
}

.tokenInput.tokenSuccess {
    border: 2px solid !important;
    border-color: #47D14B !important;
    padding-left: 0.3rem !important;
}

.btnLoginGoogle div {
    border-radius: 50px !important;
}
.kep-login-facebook {
    background-color: #fff !important;
    color: #004489 !important;
    border-radius: 50px !important;
    height: 100%;
    width: 100%;
    padding: 10px 26px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border: 1px solid #E8E8E8 !important;
}
.kep-login-facebook i {
    margin-right: -60px !important;
    margin-top: 2px;
    float: left;
}
.inputLogin {
    margin: 8px 0 !important;
}
.inputLogin fieldset {
    border-radius: 16px !important;
}
.forget-password {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0069D7;
    text-decoration-line: underline;
    text-align: right;
    cursor: pointer;
}
.registerLink {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0069D7;
    text-decoration-line: underline;
    text-align: center;
    cursor: pointer;
}
.plr-60 {
    padding: 0px 60px;
}
.messageLabel {
    display: flex;
    align-items: center;
    margin: 0;
}
.icon-style{
    border-radius: 200px;
    background-color: #C8F8C8;
    border: 5px solid #EEFFEE;
    width: 40px;
    text-align: center;
    padding: 0px !important;
    padding-top: 3px !important;
    flex: none;
    height: 40px;
    margin: 0px 10px 0px 0px!important;
    color: #0069D7;
    border-radius: 200px;
} 

.icon-error{
    border-radius: 200px;
    background-color: #FECDCA;
    border: 6px solid #FEE4E2;
    width: 40px;
    text-align: center;
    padding: 0px !important;
    padding-top: 3px !important;
    flex: none;
    height: 40px;
    margin: 0px 10px 0px 0px!important;
    border-radius: 200px;
} 


/* .css-114p2tk-MuiMobileStepper-dot, .css-2olj2q-MuiMobileStepper-dot{
    -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.26);
    border-radius: 20% !important;
    width: 50px !important;
    height: 6px !important;
    margin: 0 2px;
} */


/* .dni-marco{
    position: relative;
    height: 100vh;
    top: 0;
    width: 60vh;
} */
.dXLQyi{
    border-radius: 160px !important;
}
.dyuhLn{
    border-radius: 20px !important;
}
.image-dni{
    border-radius: 20px;
    width: 90% !important;
}

.rotate-image-dni{
    margin:-15% 0%;
    border-radius: 20px;
    width: 70% !important;
    transform: rotate(-90deg);
}

.image-face{
    color: blue;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    /* border-radius: 32px !important; */
    padding: 4px
}

.font-size-20{
    font-size: 20px !important;
}

.css-xaws23 {

    padding-bottom: 10px !important;
}
.css-1m7atsk-MuiGrid-root>.MuiGrid-item{
    padding-top: 0px !important;
}
.colorIcon{
    color: #47D14B;
}
/* .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    border-bottom: 1px solid #CEE6FF;
} */

.divider-loan-active{
    border-bottom: 0.1px solid #CEE6FF;
    margin: 12px 0px;
    width: 100%;
    padding-left: 0px !important;
}

.divider-loan-active-title{
    border-bottom: 1px solid #004489;
    margin: 12px 0px;
    width: 100%;
    padding-left: 0px !important;
}
.completed-loan{
    width: 40px;
}
.no-scroll{
    position: fixed;
    left: 0;
}

.div-color{
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 14.8em;
}

/* .gwGZaI{
    height: 111% !important;
} */
