.bg-light{
    background-color: white;
    z-index: 100;
}
.bg-aqua{
    background-color: #F5FAFF;
    z-index: 1;
}
.bg-image{
    background-image: url('../../Assets/background-public-header.svg');
    background-color: #004489;
    display: flex;
    height: 94%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 0px 80px 80px;
    margin: 0px 0px 20px 12px;
    box-shadow: 0px 12px 16px -4px rgb(0 105 215 / 10%), 0px 4px 6px -2px rgb(0 105 215 / 5%);
}
.bg-desktop{
    background-image: url('../../Assets/background-public-header.svg');
}

.bg-mobile{
    background-image: url('../../Assets/background-public-header-mobile.svg');
}

.divider {
    border-bottom: 0.1px solid #E8E8E8;
    margin: 30px 0px;
    width: 100%;
    padding-left: 0px !important;
}
.font-blue{
    color: #004489;
}
.font-footer{
    color: #A7A7A8;
    text-align: right;
    font-size: 13px !important;
}
.font-white{
    color: white;
}
.font-weight{
    font-weight: 600 !important;
}
.footer{
    padding: 20px 25px;
    background-color: white;
}
.icon-grid{
    border-radius: 200px;
    background-color: #EEFFEE;
    width: 30px;
    text-align: center;
    padding: 0px !important;
    flex: none;
    height: 35px;
    margin: 0px 10px 0px 10px!important
}
.mb-30{
    margin: 0px 0px 30px 0px !important;
}
.ml-10{
    margin-left: 10px !important;
}
.ml-40{
    margin-left: 40px !important;
}
.mt-15{
    margin-top: 15px;
}
.size-15{
    font-size: 15px !important;
}
.size-45{
    font-size: 35px !important;
}
.size-20{
    font-size: 20px !important;
}
.text-aling-center{
    text-align: center;
}
.pt-5{
    padding-top: 5px;
}
.pt-20{
    padding-top: 20px;
}
.padding-logo{
    padding: 25px 10px 120px 10px!important;
}
.pb-40{
    padding: 0px 0px 40px 0px;
}
.img-dimensions{
    width: 144px;
}
.img-desktop{
    width: 220px;
}
.img-sm{
    width: 160px;
    margin-top: 10px;
}
.plr-60{
    padding: 0px 60px;
}
.position-center{
    position: relative;
    right: 50%;
    left: 50%;
}
.position-right{
    text-align: right;
    position: absolute;
    right: 30px;
}

.position-left{
    text-align: left;
    position: absolute;
    left: 30px;
}

.position-logo-color{
    padding: 25px 10px 5px 10px!important;
    position: fixed;
    right: 0;
    background: white;
    border-bottom: 0.5px solid #d5cfcf;
}