.bottomText {
    -webkit-writing-mode: vertical-lr;
    /* old Win safari */
    writing-mode: vertical-lr;
    writing-mode: tb-rl;
    /* actually 
  writing-mode:sideways-lr; would be the one , but not avalaible everywhere so, let's transform */
    transform: scale(-1, -1);
    transform: rotate(360deg);
    /* background: blue; */
    text-align: center;
    width: 3rem;
    line-height: 1.5em;
    font-size: 1.2rem;
    white-space: wrap;
    color: white;
    /* font-weight: bold;
    font-variant: small-caps; */
}

.topText {
    -webkit-writing-mode: vertical-lr;
    /* old Win safari */
    writing-mode: vertical-lr;
    writing-mode: tb-rl;
    /* actually 
  writing-mode:sideways-lr; would be the one , but not avalaible everywhere so, let's transform */
    transform: scale(-1, -1);
    transform: rotate(360deg);
    /* background: blue; */
    text-align: center;
    width: 3rem;
    line-height: 1.5em;
    font-size: 1.2rem;
    white-space: wrap;
    color: white;
    /* font-weight: bold;
    font-variant: small-caps; */
}
